import { GraphQLClient } from 'graphql-request';
import { GraphQLClientRequestHeaders } from 'graphql-request/build/cjs/types';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: any; output: any; }
};

export type Activity = {
  __typename?: 'Activity';
  activityCategory?: Maybe<ActivityCategory>;
  activityCategoryId?: Maybe<Scalars['ID']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  dateFrom?: Maybe<Scalars['String']['output']>;
  dateTo?: Maybe<Scalars['String']['output']>;
  deleted: Scalars['Boolean']['output'];
  description?: Maybe<Scalars['String']['output']>;
  every?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  isEvent: Scalars['Boolean']['output'];
  isPublic: Scalars['Boolean']['output'];
  reports?: Maybe<Array<Report>>;
  serviceId?: Maybe<Scalars['ID']['output']>;
  style?: Maybe<Scalars['String']['output']>;
  tags?: Maybe<Tag>;
  timeFrom?: Maybe<Scalars['String']['output']>;
  timeTo?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
  userEmail: Scalars['String']['output'];
  userId?: Maybe<Scalars['ID']['output']>;
  userName: Scalars['String']['output'];
  weekdays?: Maybe<Array<Scalars['Int']['output']>>;
};

export type ActivityCategory = {
  __typename?: 'ActivityCategory';
  createdAt: Scalars['String']['output'];
  deleted: Scalars['Boolean']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  metadata?: Maybe<Scalars['JSON']['output']>;
  name: Scalars['String']['output'];
  serviceId?: Maybe<Scalars['Int']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['String']['output'];
};

export type AuthUser = {
  __typename?: 'AuthUser';
  accessToken: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export type Capture = {
  __typename?: 'Capture';
  caption?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['String']['output']>;
  deleted: Scalars['Boolean']['output'];
  fileData?: Maybe<CaptureFileData>;
  filename?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isPublic: Scalars['Boolean']['output'];
  mimeType?: Maybe<Scalars['String']['output']>;
  serviceId: Scalars['ID']['output'];
  size?: Maybe<Scalars['String']['output']>;
  tags: Tag;
  url?: Maybe<Scalars['String']['output']>;
  userEmail?: Maybe<Scalars['String']['output']>;
  userId: Scalars['ID']['output'];
  userName?: Maybe<Scalars['String']['output']>;
};

export type CaptureFileData = {
  __typename?: 'CaptureFileData';
  id: Scalars['ID']['output'];
  metadata?: Maybe<CaptureFileMetadata>;
  storage?: Maybe<Scalars['String']['output']>;
};

export type CaptureFileMetadata = {
  __typename?: 'CaptureFileMetadata';
  file_size?: Maybe<Scalars['Int']['output']>;
  filename?: Maybe<Scalars['String']['output']>;
  mime_type?: Maybe<Scalars['String']['output']>;
};

export type Child = {
  __typename?: 'Child';
  captureId?: Maybe<Scalars['ID']['output']>;
  contacts: Array<ChildContact>;
  dateOfBirth: Scalars['String']['output'];
  everything: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  gender: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  instructions: Array<ChildInstruction>;
  lastName: Scalars['String']['output'];
  profilePicture?: Maybe<ChildProfilePicture>;
};

export type ChildContact = {
  __typename?: 'ChildContact';
  email?: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  mobile?: Maybe<Scalars['String']['output']>;
  obsPlatformAuthorised?: Maybe<Scalars['Boolean']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  role: Scalars['String']['output'];
  type?: Maybe<Scalars['String']['output']>;
};

export type ChildHealthMetadata = {
  __typename?: 'ChildHealthMetadata';
  ambulance_service?: Maybe<Scalars['String']['output']>;
  doctor_name?: Maybe<Scalars['String']['output']>;
  medical_service_address?: Maybe<Scalars['String']['output']>;
  medicare_number?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  private_health_insurance?: Maybe<Scalars['String']['output']>;
  private_health_insurance_fund_membership_number?: Maybe<Scalars['String']['output']>;
  private_health_insurance_fund_name?: Maybe<Scalars['String']['output']>;
};

export type ChildInstruction = {
  __typename?: 'ChildInstruction';
  category: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
};

export type ChildMedicalMetadata = {
  __typename?: 'ChildMedicalMetadata';
  allergies?: Maybe<Scalars['String']['output']>;
  dietary_restrictions?: Maybe<Scalars['String']['output']>;
  disability?: Maybe<Scalars['String']['output']>;
  plan?: Maybe<Scalars['String']['output']>;
  special_needs?: Maybe<Scalars['String']['output']>;
};

export type ChildMessage = {
  __typename?: 'ChildMessage';
  archived?: Maybe<Scalars['Int']['output']>;
  attachments?: Maybe<Scalars['String']['output']>;
  childId: Scalars['ID']['output'];
  content: Scalars['String']['output'];
  dateSent: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  readStatus?: Maybe<Scalars['Int']['output']>;
  recipientEmail?: Maybe<Scalars['String']['output']>;
  recipientId?: Maybe<Scalars['String']['output']>;
  recipientName?: Maybe<Scalars['String']['output']>;
  seenBy?: Maybe<Array<Scalars['String']['output']>>;
  senderEmail?: Maybe<Scalars['String']['output']>;
  senderId?: Maybe<Scalars['ID']['output']>;
  senderName?: Maybe<Scalars['String']['output']>;
  serviceId: Scalars['ID']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type ChildMessagesMetadatum = {
  __typename?: 'ChildMessagesMetadatum';
  childId: Scalars['ID']['output'];
  recentDateSent: Scalars['String']['output'];
  unreadCount: Scalars['Int']['output'];
  unreadMessageIds: Array<Scalars['ID']['output']>;
};

export type ChildMetadata = {
  __typename?: 'ChildMetadata';
  aboriginal?: Maybe<Scalars['String']['output']>;
  allow_casual_bookings?: Maybe<Scalars['String']['output']>;
  country_of_birth?: Maybe<Scalars['String']['output']>;
  court_order?: Maybe<Scalars['String']['output']>;
  court_order_notes?: Maybe<Scalars['String']['output']>;
  cultural_background?: Maybe<Scalars['String']['output']>;
  healthcare_card?: Maybe<Scalars['String']['output']>;
  healthcare_card_exp?: Maybe<Scalars['String']['output']>;
  language_spoken?: Maybe<Scalars['String']['output']>;
  live_with_parent_1?: Maybe<Scalars['String']['output']>;
  priority_of_access?: Maybe<Scalars['String']['output']>;
  school?: Maybe<Scalars['String']['output']>;
  usually_called?: Maybe<Scalars['String']['output']>;
};

export type ChildProfilePicture = {
  __typename?: 'ChildProfilePicture';
  filename?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

export type ChildStruct = {
  __typename?: 'ChildStruct';
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
};

export type Comment = {
  __typename?: 'Comment';
  body: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['String']['output']>;
  deleted: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isInternalEvaluation: Scalars['Boolean']['output'];
  objId: Scalars['ID']['output'];
  objType: Scalars['String']['output'];
  serviceId: Scalars['ID']['output'];
  updatedAt?: Maybe<Scalars['String']['output']>;
  user: User;
  userId: Scalars['ID']['output'];
};

export type CreateChildMessageInput = {
  content: Scalars['String']['input'];
  recipients: Array<ReceipientsMessageInput>;
  senderEmail: Scalars['String']['input'];
  senderId: Scalars['ID']['input'];
  senderName: Scalars['String']['input'];
  serviceId: Scalars['ID']['input'];
};

export type CreateStaffMessageInput = {
  content: Scalars['String']['input'];
  recipients: Array<StaffMessageRecipientInput>;
  senderEmail: Scalars['String']['input'];
  senderId: Scalars['ID']['input'];
  senderName: Scalars['String']['input'];
  serviceId: Scalars['ID']['input'];
};

export type CustomTag = {
  __typename?: 'CustomTag';
  colour?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type Document = {
  __typename?: 'Document';
  createdAt: Scalars['String']['output'];
  date: Scalars['String']['output'];
  deleted: Scalars['Boolean']['output'];
  description?: Maybe<Scalars['String']['output']>;
  filename?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isPublic?: Maybe<Scalars['Boolean']['output']>;
  mime?: Maybe<Scalars['String']['output']>;
  size?: Maybe<Scalars['String']['output']>;
  tags?: Maybe<Tag>;
  updatedAt?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  userEmail?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['ID']['output']>;
  userName?: Maybe<Scalars['String']['output']>;
};

export type DocumentPayload = {
  __typename?: 'DocumentPayload';
  documents?: Maybe<Array<Document>>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
};

export type Educator = {
  __typename?: 'Educator';
  cardNumber?: Maybe<Scalars['String']['output']>;
  ccsUid: Scalars['String']['output'];
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  middleName?: Maybe<Scalars['String']['output']>;
  service: Service;
  serviceId: Scalars['Int']['output'];
  usualEducatorAddressLine1?: Maybe<Scalars['String']['output']>;
  usualEducatorAddressLine2?: Maybe<Scalars['String']['output']>;
  usualEducatorAddressPostCode?: Maybe<Scalars['String']['output']>;
  usualEducatorAddressState?: Maybe<Scalars['String']['output']>;
  usualEducatorAddressSuburb?: Maybe<Scalars['String']['output']>;
};

export type Enrolment = {
  __typename?: 'Enrolment';
  child: Child;
  childId: Scalars['ID']['output'];
  endDate?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  noObservation: Scalars['Boolean']['output'];
  parent1: Parent;
  parent2?: Maybe<ParentTwo>;
  parentId: Scalars['ID']['output'];
  startDate: Scalars['String']['output'];
  status?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type FilterParentsInput = {
  serviceId?: InputMaybe<Scalars['Int']['input']>;
};

export type Form = {
  __typename?: 'Form';
  answers?: Maybe<Scalars['String']['output']>;
  config?: Maybe<Scalars['JSON']['output']>;
  formDate?: Maybe<Scalars['String']['output']>;
  gridMode?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  layout: Scalars['String']['output'];
  name: Scalars['String']['output'];
  subject?: Maybe<Scalars['String']['output']>;
  submittedBy?: Maybe<Scalars['String']['output']>;
};

export type FormConfiguration = {
  __typename?: 'FormConfiguration';
  config?: Maybe<Scalars['String']['output']>;
  gridMode?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  layout: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type GetParentsInput = {
  filters?: InputMaybe<FilterParentsInput>;
  pagination?: InputMaybe<PaginationInput>;
};

export type Job = {
  __typename?: 'Job';
  roles: Array<Scalars['String']['output']>;
  serviceId: Scalars['ID']['output'];
  serviceName: Scalars['String']['output'];
};

export type KindyhubObject = {
  __typename?: 'KindyhubObject';
  status: Scalars['String']['output'];
};

export type LearningOutcome = {
  __typename?: 'LearningOutcome';
  code: Scalars['String']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  system: Scalars['String']['output'];
  title?: Maybe<Scalars['String']['output']>;
};

export type Me = {
  __typename?: 'Me';
  email: Scalars['String']['output'];
  emailVerified: Scalars['Boolean']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  jobs: Array<Maybe<Job>>;
  lastName: Scalars['String']['output'];
  mfa: Scalars['Boolean']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  userOrgDetails: UserOrgDetails;
};

export type Metadata = {
  __typename?: 'Metadata';
  child?: Maybe<ChildMetadata>;
  child_health?: Maybe<ChildHealthMetadata>;
  child_medical?: Maybe<ChildMedicalMetadata>;
  e_id?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  actionReport: Report;
  createActivityCategory: ActivityCategory;
  createCapture: Capture;
  createChildMessage: Array<ChildMessage>;
  createComment: Comment;
  createCustomTag: CustomTag;
  createDocument: DocumentPayload;
  createStaffMessage: Array<StaffMessage>;
  createTemplate: Report;
  deleteActivityCategory: ActivityCategory;
  deleteKindyhubObject: KindyhubObject;
  editDocument: SingleDocumentPayload;
  markReadChildMessage: ChildMessage;
  markReadStaffMessage: StaffMessage;
  removeChildMessage: ChildMessage;
  removeComment: Comment;
  removeCustomTag: CustomTag;
  removeStaffMessage: StaffMessage;
  revertReport: Report;
  saveActivity: Activity;
  saveCaptures: Capture;
  saveReport: Report;
  saveTags: Scalars['String']['output'];
  signin: AuthUser;
  updateActivityCategory: ActivityCategory;
  updateChildMessage: ChildMessage;
  updateChildProfilePhoto: UpdateProfilePhotoPayload;
  updateComment: Comment;
  updateCustomTag: CustomTag;
  updateStaffMessage: StaffMessage;
};


export type MutationActionReportArgs = {
  action: Scalars['String']['input'];
  id: Scalars['ID']['input'];
};


export type MutationCreateActivityCategoryArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
  metadata?: InputMaybe<Scalars['JSON']['input']>;
  name: Scalars['String']['input'];
  serviceId: Scalars['ID']['input'];
};


export type MutationCreateCaptureArgs = {
  date: Scalars['String']['input'];
  fileData: Scalars['String']['input'];
  serviceId: Scalars['ID']['input'];
};


export type MutationCreateChildMessageArgs = {
  createChildMessageInput: CreateChildMessageInput;
};


export type MutationCreateCommentArgs = {
  body: Scalars['String']['input'];
  isInternalEvaluation?: InputMaybe<Scalars['Boolean']['input']>;
  objId: Scalars['ID']['input'];
  objType?: InputMaybe<Scalars['String']['input']>;
  serviceId: Scalars['ID']['input'];
};


export type MutationCreateCustomTagArgs = {
  colour?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  serviceId: Scalars['ID']['input'];
};


export type MutationCreateDocumentArgs = {
  date: Scalars['String']['input'];
  fileData: Scalars['String']['input'];
  serviceId: Scalars['ID']['input'];
};


export type MutationCreateStaffMessageArgs = {
  input: CreateStaffMessageInput;
};


export type MutationCreateTemplateArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteActivityCategoryArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteKindyhubObjectArgs = {
  objId: Scalars['ID']['input'];
  objType: Scalars['String']['input'];
};


export type MutationEditDocumentArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
  documentId: Scalars['ID']['input'];
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  serviceId: Scalars['ID']['input'];
};


export type MutationMarkReadChildMessageArgs = {
  childId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
  recipientEmail: Scalars['String']['input'];
  serviceId: Scalars['ID']['input'];
};


export type MutationMarkReadStaffMessageArgs = {
  id: Scalars['ID']['input'];
  recipientUserId: Scalars['ID']['input'];
  serviceId: Scalars['ID']['input'];
};


export type MutationRemoveChildMessageArgs = {
  id: Scalars['ID']['input'];
};


export type MutationRemoveCommentArgs = {
  id: Scalars['ID']['input'];
};


export type MutationRemoveCustomTagArgs = {
  id: Scalars['ID']['input'];
};


export type MutationRemoveStaffMessageArgs = {
  id: Scalars['ID']['input'];
};


export type MutationRevertReportArgs = {
  id: Scalars['ID']['input'];
  versionId: Scalars['ID']['input'];
};


export type MutationSaveActivityArgs = {
  activityCategoryId?: InputMaybe<Scalars['ID']['input']>;
  activityId?: InputMaybe<Scalars['ID']['input']>;
  dateFrom: Scalars['String']['input'];
  dateTo?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  every?: InputMaybe<Scalars['Int']['input']>;
  isEvent?: InputMaybe<Scalars['Boolean']['input']>;
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  serviceId: Scalars['ID']['input'];
  style?: InputMaybe<Scalars['String']['input']>;
  timeFrom?: InputMaybe<Scalars['String']['input']>;
  timeTo?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  weekdays: Array<Scalars['Int']['input']>;
};


export type MutationSaveCapturesArgs = {
  caption?: InputMaybe<Scalars['String']['input']>;
  captureId: Scalars['ID']['input'];
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  serviceId: Scalars['ID']['input'];
};


export type MutationSaveReportArgs = {
  content?: InputMaybe<Scalars['String']['input']>;
  date?: InputMaybe<Scalars['String']['input']>;
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  isTemplate?: InputMaybe<Scalars['Boolean']['input']>;
  reportId?: InputMaybe<Scalars['ID']['input']>;
  serviceId: Scalars['ID']['input'];
  smartFormSubmissionIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  template?: InputMaybe<Scalars['String']['input']>;
  theme?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSaveTagsArgs = {
  children?: InputMaybe<Array<Scalars['ID']['input']>>;
  customTags?: InputMaybe<Array<Scalars['ID']['input']>>;
  learningOutcomes?: InputMaybe<Array<Scalars['ID']['input']>>;
  newCustomTags?: InputMaybe<Array<Scalars['ID']['input']>>;
  nextActivities?: InputMaybe<Array<Scalars['ID']['input']>>;
  nextReports?: InputMaybe<Array<Scalars['ID']['input']>>;
  objectId: Scalars['ID']['input'];
  objectType: Scalars['String']['input'];
  rooms?: InputMaybe<Array<Scalars['ID']['input']>>;
  users?: InputMaybe<Array<Scalars['ID']['input']>>;
};


export type MutationSigninArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationUpdateActivityCategoryArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  metadata?: InputMaybe<Scalars['JSON']['input']>;
  name: Scalars['String']['input'];
};


export type MutationUpdateChildMessageArgs = {
  payload: UpdateChildMessageInput;
};


export type MutationUpdateChildProfilePhotoArgs = {
  captureId?: InputMaybe<Scalars['ID']['input']>;
  childId: Scalars['ID']['input'];
};


export type MutationUpdateCommentArgs = {
  body: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  isInternalEvaluation?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationUpdateCustomTagArgs = {
  colour?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};


export type MutationUpdateStaffMessageArgs = {
  input: UpdateStaffMessageInput;
};

export type Note = {
  __typename?: 'Note';
  ackAt?: Maybe<Scalars['String']['output']>;
  ackBy?: Maybe<Scalars['String']['output']>;
  channel?: Maybe<Scalars['String']['output']>;
  content: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  parentId: Scalars['String']['output'];
  parentName?: Maybe<Scalars['String']['output']>;
  reply?: Maybe<Scalars['String']['output']>;
};

export type Notification = {
  __typename?: 'Notification';
  actionType: Scalars['String']['output'];
  capture?: Maybe<Capture>;
  comment?: Maybe<Comment>;
  createdAt?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<User>;
  id: Scalars['ID']['output'];
  objId: Scalars['ID']['output'];
  objType: Scalars['String']['output'];
  report?: Maybe<Report>;
  seenBy?: Maybe<Array<Scalars['ID']['output']>>;
};

export type NotificationMetadata = {
  __typename?: 'NotificationMetadata';
  total: Scalars['Int']['output'];
};

export type NotificationWithMetadata = {
  __typename?: 'NotificationWithMetadata';
  metadata: NotificationMetadata;
  notifications: Array<Notification>;
};

export type NotificationsMetadata = {
  __typename?: 'NotificationsMetadata';
  objType: Scalars['String']['output'];
  unseenCount: Scalars['Int']['output'];
};

export type Organisation = {
  __typename?: 'Organisation';
  id: Scalars['ID']['output'];
  logoData?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export type PaginationInput = {
  limit: Scalars['Int']['input'];
  page: Scalars['Int']['input'];
};

export type Parent = {
  __typename?: 'Parent';
  email?: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  lastName: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
};

export type ParentTwo = {
  __typename?: 'ParentTwo';
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
};

export type Query = {
  __typename?: 'Query';
  activities: Array<Activity>;
  activity: Activity;
  activityCategories: Array<ActivityCategory>;
  activityCategory: ActivityCategory;
  bookedSessions: Array<ServiceSession>;
  capture?: Maybe<Capture>;
  captures: Array<Capture>;
  child: Child;
  childMessages: Array<ChildMessage>;
  childMessagesMetadata: Array<ChildMessagesMetadatum>;
  children: Array<Child>;
  comments: Array<Comment>;
  customTag: CustomTag;
  customTags: Array<CustomTag>;
  documents: Array<Document>;
  educator: Educator;
  educators: Array<Educator>;
  enrolment: Enrolment;
  enrolments: Array<Enrolment>;
  formConfig: Scalars['String']['output'];
  formConfiguration: FormConfiguration;
  formConfigurations: Array<FormConfiguration>;
  forms: Array<Form>;
  jobs: Array<Maybe<Job>>;
  learningOutcome: LearningOutcome;
  learningOutcomes: Array<LearningOutcome>;
  me: Me;
  note: Note;
  notes: Array<Note>;
  notifications: NotificationWithMetadata;
  notificationsMetadata: Array<NotificationsMetadata>;
  organisation: Organisation;
  parent: Parent;
  parents: Array<Parent>;
  report?: Maybe<Report>;
  reports: Array<Report>;
  room: Room;
  rooms: Array<Room>;
  service: Service;
  sessions: Array<ServiceSession>;
  smartForm: SmartForm;
  smartForms: Array<SmartForm>;
  staffMessages: Array<StaffMessage>;
  staffMessagesMetadata: Array<StaffMessagesMetadatum>;
  user: User;
  userOrganisationRoles: Array<UserOrganisationRole>;
};


export type QueryActivitiesArgs = {
  children?: InputMaybe<Array<Scalars['Int']['input']>>;
  customTags?: InputMaybe<Array<Scalars['Int']['input']>>;
  dateFrom: Scalars['String']['input'];
  dateTo?: InputMaybe<Scalars['String']['input']>;
  ids?: InputMaybe<Array<Scalars['Int']['input']>>;
  learningOutcomes?: InputMaybe<Array<Scalars['Int']['input']>>;
  nextReports?: InputMaybe<Array<Scalars['Int']['input']>>;
  rooms?: InputMaybe<Array<Scalars['Int']['input']>>;
  serviceId: Scalars['ID']['input'];
  style?: InputMaybe<Scalars['String']['input']>;
  users?: InputMaybe<Array<Scalars['Int']['input']>>;
};


export type QueryActivityArgs = {
  id: Scalars['ID']['input'];
  serviceId: Scalars['ID']['input'];
};


export type QueryActivityCategoriesArgs = {
  serviceId: Scalars['ID']['input'];
};


export type QueryActivityCategoryArgs = {
  id: Scalars['ID']['input'];
  serviceId: Scalars['ID']['input'];
};


export type QueryBookedSessionsArgs = {
  date: Scalars['String']['input'];
  dateTo?: InputMaybe<Scalars['String']['input']>;
  roomId?: InputMaybe<Scalars['String']['input']>;
  serviceId: Scalars['ID']['input'];
};


export type QueryCaptureArgs = {
  id: Scalars['ID']['input'];
  serviceId: Scalars['ID']['input'];
};


export type QueryCapturesArgs = {
  children?: InputMaybe<Array<Scalars['Int']['input']>>;
  customTags?: InputMaybe<Array<Scalars['Int']['input']>>;
  dateFrom: Scalars['String']['input'];
  dateTo?: InputMaybe<Scalars['String']['input']>;
  learningOutcomes?: InputMaybe<Array<Scalars['Int']['input']>>;
  nextReports?: InputMaybe<Array<Scalars['Int']['input']>>;
  rooms?: InputMaybe<Array<Scalars['Int']['input']>>;
  serviceId: Scalars['ID']['input'];
  users?: InputMaybe<Array<Scalars['Int']['input']>>;
};


export type QueryChildArgs = {
  id: Scalars['Int']['input'];
};


export type QueryChildMessagesArgs = {
  childId: Scalars['ID']['input'];
  dateFrom?: InputMaybe<Scalars['String']['input']>;
  dateTo?: InputMaybe<Scalars['String']['input']>;
  serviceId: Scalars['ID']['input'];
};


export type QueryChildMessagesMetadataArgs = {
  childIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  email: Scalars['String']['input'];
  serviceId: Scalars['ID']['input'];
};


export type QueryChildrenArgs = {
  serviceId: Scalars['ID']['input'];
};


export type QueryCommentsArgs = {
  isInternalEvaluation?: InputMaybe<Scalars['Boolean']['input']>;
  objId: Scalars['ID']['input'];
  objType: Scalars['String']['input'];
  serviceId: Scalars['ID']['input'];
};


export type QueryCustomTagArgs = {
  id: Scalars['ID']['input'];
  serviceId: Scalars['ID']['input'];
};


export type QueryCustomTagsArgs = {
  serviceId: Scalars['ID']['input'];
};


export type QueryDocumentsArgs = {
  children?: InputMaybe<Array<Scalars['Int']['input']>>;
  customTags?: InputMaybe<Array<Scalars['Int']['input']>>;
  dateFrom: Scalars['String']['input'];
  dateTo?: InputMaybe<Scalars['String']['input']>;
  learningOutcomes?: InputMaybe<Array<Scalars['Int']['input']>>;
  rooms?: InputMaybe<Array<Scalars['Int']['input']>>;
  serviceId: Scalars['Int']['input'];
  users?: InputMaybe<Array<Scalars['Int']['input']>>;
};


export type QueryEducatorArgs = {
  id: Scalars['Int']['input'];
};


export type QueryEducatorsArgs = {
  serviceId: Scalars['Int']['input'];
};


export type QueryEnrolmentArgs = {
  id: Scalars['Int']['input'];
};


export type QueryEnrolmentsArgs = {
  endDate?: InputMaybe<Scalars['String']['input']>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  serviceId: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['String']['input']>;
};


export type QueryFormConfigurationArgs = {
  id: Scalars['ID']['input'];
  serviceId: Scalars['ID']['input'];
};


export type QueryFormConfigurationsArgs = {
  serviceId: Scalars['ID']['input'];
};


export type QueryFormsArgs = {
  serviceId: Scalars['Int']['input'];
};


export type QueryLearningOutcomeArgs = {
  id: Scalars['Int']['input'];
};


export type QueryLearningOutcomesArgs = {
  serviceId: Scalars['Int']['input'];
};


export type QueryNoteArgs = {
  id: Scalars['Int']['input'];
};


export type QueryNotesArgs = {
  serviceId: Scalars['Int']['input'];
};


export type QueryNotificationsArgs = {
  objType: Scalars['String']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
  serviceId: Scalars['Int']['input'];
};


export type QueryNotificationsMetadataArgs = {
  serviceId: Scalars['Int']['input'];
};


export type QueryOrganisationArgs = {
  id: Scalars['Int']['input'];
};


export type QueryParentArgs = {
  id: Scalars['Int']['input'];
};


export type QueryParentsArgs = {
  GetParentsInput: GetParentsInput;
};


export type QueryReportArgs = {
  id: Scalars['ID']['input'];
  serviceId: Scalars['ID']['input'];
};


export type QueryReportsArgs = {
  children?: InputMaybe<Array<Scalars['Int']['input']>>;
  customTags?: InputMaybe<Array<Scalars['Int']['input']>>;
  dateFrom: Scalars['String']['input'];
  dateTo?: InputMaybe<Scalars['String']['input']>;
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  isTemplate?: InputMaybe<Scalars['Boolean']['input']>;
  learningOutcomes?: InputMaybe<Array<Scalars['Int']['input']>>;
  nextReports?: InputMaybe<Array<Scalars['Int']['input']>>;
  rooms?: InputMaybe<Array<Scalars['Int']['input']>>;
  serviceId: Scalars['ID']['input'];
  status?: InputMaybe<Array<Scalars['String']['input']>>;
  template?: InputMaybe<Scalars['String']['input']>;
  users?: InputMaybe<Array<Scalars['Int']['input']>>;
};


export type QueryRoomArgs = {
  id: Scalars['Int']['input'];
  serviceId: Scalars['ID']['input'];
};


export type QueryRoomsArgs = {
  serviceId: Scalars['ID']['input'];
};


export type QueryServiceArgs = {
  id: Scalars['Int']['input'];
};


export type QuerySessionsArgs = {
  date: Scalars['String']['input'];
  dateTo?: InputMaybe<Scalars['String']['input']>;
  roomId?: InputMaybe<Scalars['String']['input']>;
  serviceId: Scalars['ID']['input'];
};


export type QuerySmartFormArgs = {
  id: Scalars['ID']['input'];
  serviceId: Scalars['ID']['input'];
};


export type QuerySmartFormsArgs = {
  serviceId: Scalars['ID']['input'];
};


export type QueryStaffMessagesArgs = {
  dateFrom?: InputMaybe<Scalars['String']['input']>;
  dateTo?: InputMaybe<Scalars['String']['input']>;
  serviceId: Scalars['ID']['input'];
  topic?: InputMaybe<Scalars['ID']['input']>;
  userId: Scalars['ID']['input'];
};


export type QueryStaffMessagesMetadataArgs = {
  serviceId: Scalars['ID']['input'];
  userId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryUserArgs = {
  id: Scalars['Int']['input'];
};


export type QueryUserOrganisationRolesArgs = {
  serviceId: Scalars['ID']['input'];
};

export type ReceipientsMessageInput = {
  childId: Scalars['ID']['input'];
  recipientEmail: Scalars['String']['input'];
  recipientId: Scalars['ID']['input'];
  recipientName: Scalars['String']['input'];
};

export type Report = {
  __typename?: 'Report';
  content?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  date?: Maybe<Scalars['String']['output']>;
  deleted: Scalars['Boolean']['output'];
  history?: Maybe<Array<ReportEvent>>;
  id: Scalars['ID']['output'];
  isPublic: Scalars['Boolean']['output'];
  isTemplate?: Maybe<Scalars['Boolean']['output']>;
  serviceId: Scalars['ID']['output'];
  smartFormSubmissionIds?: Maybe<Array<Scalars['ID']['output']>>;
  status?: Maybe<Scalars['String']['output']>;
  tags: Tag;
  template?: Maybe<Scalars['String']['output']>;
  theme?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['String']['output'];
  userEmail: Scalars['String']['output'];
  userId: Scalars['ID']['output'];
  userName: Scalars['String']['output'];
};

export type ReportEvent = {
  __typename?: 'ReportEvent';
  createdAt: Scalars['String']['output'];
  event: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  userEmail?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['ID']['output']>;
  userName?: Maybe<Scalars['String']['output']>;
};

export type Room = {
  __typename?: 'Room';
  age?: Maybe<Scalars['Int']['output']>;
  ageGroupId?: Maybe<Scalars['ID']['output']>;
  capacity: Scalars['Int']['output'];
  defaultSessionCodeId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  inactive: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  publicRoom: Scalars['Boolean']['output'];
  roomType: Scalars['String']['output'];
  service?: Maybe<Service>;
  serviceId: Scalars['ID']['output'];
};

export type Service = {
  __typename?: 'Service';
  id: Scalars['ID']['output'];
  locationId: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  opDays: Array<Scalars['String']['output']>;
  opstatus: Scalars['String']['output'];
  organisationId: Scalars['Int']['output'];
  state?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
  tzName?: Maybe<Scalars['String']['output']>;
  uid: Scalars['String']['output'];
};

export type ServiceSession = {
  __typename?: 'ServiceSession';
  childFirstName: Scalars['String']['output'];
  childId: Scalars['ID']['output'];
  childLastName: Scalars['String']['output'];
  date: Scalars['String']['output'];
  enrolmentId: Scalars['ID']['output'];
  noObservation?: Maybe<Scalars['Boolean']['output']>;
  room: Scalars['String']['output'];
  roomId: Scalars['ID']['output'];
};

export type SingleDocumentPayload = {
  __typename?: 'SingleDocumentPayload';
  document?: Maybe<Document>;
  errors?: Maybe<Array<Scalars['String']['output']>>;
};

export type SmartForm = {
  __typename?: 'SmartForm';
  config?: Maybe<Scalars['JSON']['output']>;
  gridMode: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  layout: Scalars['String']['output'];
  name: Scalars['String']['output'];
  organisationId: Scalars['Int']['output'];
};

export type StaffMessage = {
  __typename?: 'StaffMessage';
  attachments?: Maybe<Scalars['String']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  dateSent: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  readStatus?: Maybe<Scalars['String']['output']>;
  recipientEmail?: Maybe<Scalars['String']['output']>;
  recipientId?: Maybe<Scalars['String']['output']>;
  recipientName?: Maybe<Scalars['String']['output']>;
  senderEmail?: Maybe<Scalars['String']['output']>;
  senderId?: Maybe<Scalars['String']['output']>;
  senderName?: Maybe<Scalars['String']['output']>;
  serviceId: Scalars['ID']['output'];
  updatedAt: Scalars['String']['output'];
};

export type StaffMessageRecipientInput = {
  recipientEmail: Scalars['String']['input'];
  recipientId: Scalars['ID']['input'];
  recipientName: Scalars['String']['input'];
};

export type StaffMessagesMetadatum = {
  __typename?: 'StaffMessagesMetadatum';
  recentDateSent: Scalars['String']['output'];
  topic: Scalars['ID']['output'];
  unreadCount: Scalars['Int']['output'];
  unreadMessageIds: Array<Scalars['ID']['output']>;
};

export type Tag = {
  __typename?: 'Tag';
  children: Array<Maybe<ChildStruct>>;
  customTags: Array<Maybe<Scalars['Int']['output']>>;
  learningOutcomes: Array<Maybe<Scalars['Int']['output']>>;
  nextActivities: Array<Maybe<Scalars['Int']['output']>>;
  nextReports: Array<Maybe<Scalars['Int']['output']>>;
  rooms: Array<Maybe<Scalars['Int']['output']>>;
  users: Array<Maybe<Scalars['Int']['output']>>;
};

export type UpdateChildMessageInput = {
  content: Scalars['String']['input'];
  id: Scalars['ID']['input'];
};

export type UpdateProfilePhotoPayload = {
  __typename?: 'UpdateProfilePhotoPayload';
  errors?: Maybe<Array<Scalars['String']['output']>>;
  success: Scalars['Boolean']['output'];
};

export type UpdateStaffMessageInput = {
  content: Scalars['String']['input'];
  id: Scalars['ID']['input'];
};

export type User = {
  __typename?: 'User';
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  jobs: Array<Job>;
  lastName: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  roles: UserRoles;
  userOrgDetails: UserOrgDetails;
};

export type UserOrgDetails = {
  __typename?: 'UserOrgDetails';
  id: Scalars['ID']['output'];
  role: Scalars['String']['output'];
  roleEnd?: Maybe<Scalars['String']['output']>;
  roleStart: Scalars['String']['output'];
};

export type UserOrganisationDetails = {
  __typename?: 'UserOrganisationDetails';
  id: Scalars['ID']['output'];
  role: Scalars['String']['output'];
  roleEnd?: Maybe<Scalars['String']['output']>;
  roleStart?: Maybe<Scalars['String']['output']>;
};

export type UserOrganisationRole = {
  __typename?: 'UserOrganisationRole';
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  userOrgDetails: UserOrganisationDetails;
};

export type UserRoles = {
  __typename?: 'UserRoles';
  roles: Array<Scalars['String']['output']>;
  service?: Maybe<Service>;
  serviceId: Scalars['Int']['output'];
};

export type GetActivityQueryVariables = Exact<{
  serviceId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
}>;


export type GetActivityQuery = { __typename?: 'Query', activity: { __typename?: 'Activity', createdAt?: string | null, dateFrom?: string | null, dateTo?: string | null, description?: string | null, every?: number | null, id: string, title: string, updatedAt?: string | null, userEmail: string, userName: string, weekdays?: Array<number> | null, timeFrom?: string | null, timeTo?: string | null, style?: string | null, isPublic: boolean, isEvent: boolean, activityCategory?: { __typename?: 'ActivityCategory', id: number, name: string, description?: string | null, metadata?: any | null, slug?: string | null, deleted: boolean } | null, reports?: Array<{ __typename?: 'Report', id: string, title?: string | null, createdAt: string, updatedAt: string, date?: string | null, isTemplate?: boolean | null, isPublic: boolean, status?: string | null, tags: { __typename?: 'Tag', nextActivities: Array<number | null> } }> | null, tags?: { __typename?: 'Tag', learningOutcomes: Array<number | null>, customTags: Array<number | null>, rooms: Array<number | null>, users: Array<number | null>, children: Array<{ __typename?: 'ChildStruct', id: string, firstName: string, lastName: string } | null> } | null } };

export type GetActivitiesQueryVariables = Exact<{
  serviceId: Scalars['ID']['input'];
  dateFrom: Scalars['String']['input'];
  dateTo?: InputMaybe<Scalars['String']['input']>;
  children?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>;
  customTags?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>;
  learningOutcomes?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>;
  rooms?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>;
  users?: InputMaybe<Array<Scalars['Int']['input']> | Scalars['Int']['input']>;
  style?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetActivitiesQuery = { __typename?: 'Query', activities: Array<{ __typename?: 'Activity', createdAt?: string | null, dateFrom?: string | null, dateTo?: string | null, description?: string | null, every?: number | null, id: string, title: string, updatedAt?: string | null, userEmail: string, userName: string, weekdays?: Array<number> | null, timeFrom?: string | null, timeTo?: string | null, style?: string | null, isPublic: boolean, isEvent: boolean, activityCategory?: { __typename?: 'ActivityCategory', id: number, name: string, description?: string | null, metadata?: any | null, slug?: string | null, deleted: boolean } | null, reports?: Array<{ __typename?: 'Report', id: string, title?: string | null, createdAt: string, updatedAt: string, date?: string | null, isTemplate?: boolean | null, isPublic: boolean, status?: string | null, tags: { __typename?: 'Tag', nextActivities: Array<number | null> } }> | null, tags?: { __typename?: 'Tag', learningOutcomes: Array<number | null>, customTags: Array<number | null>, rooms: Array<number | null>, users: Array<number | null>, children: Array<{ __typename?: 'ChildStruct', id: string, firstName: string, lastName: string } | null> } | null }> };

export type GetActivityCategoriesQueryVariables = Exact<{
  serviceId: Scalars['ID']['input'];
}>;


export type GetActivityCategoriesQuery = { __typename?: 'Query', activityCategories: Array<{ __typename?: 'ActivityCategory', id: number, name: string, description?: string | null, metadata?: any | null, serviceId?: number | null, slug?: string | null, deleted: boolean }> };

export type PostSaveActivityMutationVariables = Exact<{
  serviceId: Scalars['ID']['input'];
  activityId?: InputMaybe<Scalars['ID']['input']>;
  title: Scalars['String']['input'];
  style?: InputMaybe<Scalars['String']['input']>;
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  dateFrom: Scalars['String']['input'];
  dateTo?: InputMaybe<Scalars['String']['input']>;
  timeFrom?: InputMaybe<Scalars['String']['input']>;
  timeTo?: InputMaybe<Scalars['String']['input']>;
  every?: InputMaybe<Scalars['Int']['input']>;
  weekdays: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
  isEvent?: InputMaybe<Scalars['Boolean']['input']>;
  activityCategoryId?: InputMaybe<Scalars['ID']['input']>;
}>;


export type PostSaveActivityMutation = { __typename?: 'Mutation', saveActivity: { __typename?: 'Activity', id: string, dateFrom?: string | null, dateTo?: string | null, every?: number | null, weekdays?: Array<number> | null } };

export type PostCreateActivityCategoryMutationVariables = Exact<{
  serviceId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  metadata?: InputMaybe<Scalars['JSON']['input']>;
}>;


export type PostCreateActivityCategoryMutation = { __typename?: 'Mutation', createActivityCategory: { __typename?: 'ActivityCategory', id: number, createdAt: string, updatedAt: string, name: string, metadata?: any | null, description?: string | null, serviceId?: number | null, slug?: string | null, deleted: boolean } };

export type PostUpdateActivityCategoryMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  metadata?: InputMaybe<Scalars['JSON']['input']>;
}>;


export type PostUpdateActivityCategoryMutation = { __typename?: 'Mutation', updateActivityCategory: { __typename?: 'ActivityCategory', id: number, name: string, metadata?: any | null, description?: string | null, serviceId?: number | null, slug?: string | null, deleted: boolean } };

export type PostDeleteActivityCategoryMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type PostDeleteActivityCategoryMutation = { __typename?: 'Mutation', deleteActivityCategory: { __typename?: 'ActivityCategory', serviceId?: number | null, id: number } };

export type GetLearningOutcomesQueryVariables = Exact<{
  serviceId: Scalars['Int']['input'];
}>;


export type GetLearningOutcomesQuery = { __typename?: 'Query', learningOutcomes: Array<{ __typename?: 'LearningOutcome', code: string, description: string, id: string, system: string, title?: string | null }> };

export type GetBookedSessionsQueryVariables = Exact<{
  serviceId: Scalars['ID']['input'];
  date: Scalars['String']['input'];
  dateTo?: InputMaybe<Scalars['String']['input']>;
  roomId?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetBookedSessionsQuery = { __typename?: 'Query', bookedSessions: Array<{ __typename?: 'ServiceSession', childId: string, room: string, roomId: string, enrolmentId: string, childFirstName: string, childLastName: string, noObservation?: boolean | null }> };

export type GetSessionsQueryVariables = Exact<{
  serviceId: Scalars['ID']['input'];
  date: Scalars['String']['input'];
  dateTo?: InputMaybe<Scalars['String']['input']>;
  roomId?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetSessionsQuery = { __typename?: 'Query', sessions: Array<{ __typename?: 'ServiceSession', childId: string, room: string, roomId: string, enrolmentId: string, childFirstName: string, childLastName: string, noObservation?: boolean | null }> };

export type GetServiceInfoQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type GetServiceInfoQuery = { __typename?: 'Query', service: { __typename?: 'Service', id: string, name: string, opDays: Array<string>, opstatus: string, organisationId: number } };

export type GetOrganisationQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type GetOrganisationQuery = { __typename?: 'Query', organisation: { __typename?: 'Organisation', id: string, logoData?: string | null, name: string } };

export type GetRoomsQueryVariables = Exact<{
  serviceId: Scalars['ID']['input'];
}>;


export type GetRoomsQuery = { __typename?: 'Query', rooms: Array<{ __typename?: 'Room', id: string, inactive: boolean, name: string, roomType: string, serviceId: string }> };

export type GetCurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentUserQuery = { __typename?: 'Query', me: { __typename?: 'Me', id: string, email: string, emailVerified: boolean, firstName: string, lastName: string, mfa: boolean, jobs: Array<{ __typename?: 'Job', serviceId: string, serviceName: string, roles: Array<string> } | null> } };

export type PostSignInMutationVariables = Exact<{
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;


export type PostSignInMutation = { __typename?: 'Mutation', signin: { __typename?: 'AuthUser', accessToken: string, userId: string } };

export type PostDeleteKindyHubObjectMutationVariables = Exact<{
  objId: Scalars['ID']['input'];
  objType: Scalars['String']['input'];
}>;


export type PostDeleteKindyHubObjectMutation = { __typename?: 'Mutation', deleteKindyhubObject: { __typename?: 'KindyhubObject', status: string } };

export type GetChildrenListQueryVariables = Exact<{
  serviceId: Scalars['Int']['input'];
  startDate?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetChildrenListQuery = { __typename?: 'Query', enrolments: Array<{ __typename?: 'Enrolment', id: string, childId: string, status?: string | null, startDate: string, endDate?: string | null, noObservation: boolean, child: { __typename?: 'Child', id: string, firstName: string, gender: string, dateOfBirth: string, lastName: string, profilePicture?: { __typename?: 'ChildProfilePicture', id: string, url?: string | null } | null, instructions: Array<{ __typename?: 'ChildInstruction', category: string, description?: string | null }> } }> };

export type GetChildrenParentListQueryVariables = Exact<{
  serviceId: Scalars['Int']['input'];
  searchText?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetChildrenParentListQuery = { __typename?: 'Query', enrolments: Array<{ __typename?: 'Enrolment', id: string, status?: string | null, parentId: string, childId: string, noObservation: boolean, child: { __typename?: 'Child', id: string, firstName: string, lastName: string }, parent1: { __typename?: 'Parent', id: number, firstName: string, lastName: string, email?: string | null }, parent2?: { __typename?: 'ParentTwo', id?: string | null, firstName?: string | null, lastName?: string | null, phone?: string | null, email?: string | null } | null }> };

export type GetChildDetailQueryVariables = Exact<{
  enrolmentId: Scalars['Int']['input'];
}>;


export type GetChildDetailQuery = { __typename?: 'Query', formConfig: string, enrolment: { __typename?: 'Enrolment', id: string, childId: string, status?: string | null, startDate: string, endDate?: string | null, parentId: string, noObservation: boolean, child: { __typename?: 'Child', id: string, firstName: string, dateOfBirth: string, lastName: string, everything: string, profilePicture?: { __typename?: 'ChildProfilePicture', id: string, url?: string | null } | null, contacts: Array<{ __typename?: 'ChildContact', firstName: string, lastName: string, email?: string | null, mobile?: string | null, phone?: string | null, role: string }> }, parent1: { __typename?: 'Parent', id: number, firstName: string, lastName: string, phone?: string | null, email?: string | null } } };

export type PostUpdateChildProfilePictureMutationVariables = Exact<{
  childId: Scalars['ID']['input'];
  captureId: Scalars['ID']['input'];
}>;


export type PostUpdateChildProfilePictureMutation = { __typename?: 'Mutation', updateChildProfilePhoto: { __typename?: 'UpdateProfilePhotoPayload', errors?: Array<string> | null, success: boolean } };

export type GetCustomTagsQueryVariables = Exact<{
  serviceId: Scalars['ID']['input'];
}>;


export type GetCustomTagsQuery = { __typename?: 'Query', customTags: Array<{ __typename?: 'CustomTag', name: string, id: string, description?: string | null, colour?: string | null }> };

export type PostCreateCustomTagMutationVariables = Exact<{
  serviceId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  colour?: InputMaybe<Scalars['String']['input']>;
}>;


export type PostCreateCustomTagMutation = { __typename?: 'Mutation', createCustomTag: { __typename?: 'CustomTag', id: string, name: string, description?: string | null, colour?: string | null } };

export type PostEditCustomTagMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  colour?: InputMaybe<Scalars['String']['input']>;
}>;


export type PostEditCustomTagMutation = { __typename?: 'Mutation', updateCustomTag: { __typename?: 'CustomTag', id: string, name: string, description?: string | null, colour?: string | null } };

export type PostDeleteCustomTagMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type PostDeleteCustomTagMutation = { __typename?: 'Mutation', removeCustomTag: { __typename?: 'CustomTag', id: string, name: string, description?: string | null, colour?: string | null } };

export type PostSaveTagsMutationVariables = Exact<{
  objectType: Scalars['String']['input'];
  objectId: Scalars['ID']['input'];
  rooms?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  children?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  learningOutcomes?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  customTags?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  nextActivities?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  nextReports?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  newCustomTags?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  users?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
}>;


export type PostSaveTagsMutation = { __typename?: 'Mutation', saveTags: string };

export type GetUserOrganisationRolesQueryVariables = Exact<{
  serviceId: Scalars['ID']['input'];
}>;


export type GetUserOrganisationRolesQuery = { __typename?: 'Query', userOrganisationRoles: Array<{ __typename?: 'UserOrganisationRole', id: string, email: string, firstName: string, lastName: string, userOrgDetails: { __typename?: 'UserOrganisationDetails', id: string, role: string, roleEnd?: string | null, roleStart?: string | null } }> };


export const GetActivityDocument = gql`
    query getActivity($serviceId: ID!, $id: ID!) {
  activity(serviceId: $serviceId, id: $id) {
    createdAt
    dateFrom
    dateTo
    description
    every
    id
    title
    updatedAt
    userEmail
    userName
    weekdays
    timeFrom
    timeTo
    style
    isPublic
    isEvent
    activityCategory {
      id
      name
      description
      metadata
      slug
      deleted
    }
    reports {
      id
      title
      createdAt
      updatedAt
      date
      isTemplate
      isPublic
      status
      tags {
        nextActivities
      }
    }
    tags {
      children {
        id
        firstName
        lastName
      }
      learningOutcomes
      customTags
      rooms
      users
    }
  }
}
    `;
export const GetActivitiesDocument = gql`
    query getActivities($serviceId: ID!, $dateFrom: String!, $dateTo: String, $children: [Int!], $customTags: [Int!], $learningOutcomes: [Int!], $rooms: [Int!], $users: [Int!], $style: String) {
  activities(
    serviceId: $serviceId
    dateFrom: $dateFrom
    dateTo: $dateTo
    children: $children
    customTags: $customTags
    learningOutcomes: $learningOutcomes
    rooms: $rooms
    users: $users
    style: $style
  ) {
    createdAt
    dateFrom
    dateTo
    description
    every
    id
    title
    updatedAt
    userEmail
    userName
    weekdays
    timeFrom
    timeTo
    style
    isPublic
    isEvent
    activityCategory {
      id
      name
      description
      metadata
      slug
      deleted
    }
    reports {
      id
      title
      createdAt
      updatedAt
      date
      isTemplate
      isPublic
      status
      tags {
        nextActivities
      }
    }
    tags {
      children {
        id
        firstName
        lastName
      }
      learningOutcomes
      customTags
      rooms
      users
    }
  }
}
    `;
export const GetActivityCategoriesDocument = gql`
    query getActivityCategories($serviceId: ID!) {
  activityCategories(serviceId: $serviceId) {
    id
    name
    description
    metadata
    serviceId
    slug
    deleted
  }
}
    `;
export const PostSaveActivityDocument = gql`
    mutation postSaveActivity($serviceId: ID!, $activityId: ID, $title: String!, $style: String, $isPublic: Boolean, $description: String, $dateFrom: String!, $dateTo: String, $timeFrom: String, $timeTo: String, $every: Int, $weekdays: [Int!]!, $isEvent: Boolean, $activityCategoryId: ID) {
  saveActivity(
    serviceId: $serviceId
    dateFrom: $dateFrom
    dateTo: $dateTo
    every: $every
    title: $title
    description: $description
    weekdays: $weekdays
    timeFrom: $timeFrom
    timeTo: $timeTo
    style: $style
    activityId: $activityId
    isPublic: $isPublic
    isEvent: $isEvent
    activityCategoryId: $activityCategoryId
  ) {
    id
    dateFrom
    dateTo
    every
    weekdays
  }
}
    `;
export const PostCreateActivityCategoryDocument = gql`
    mutation postCreateActivityCategory($serviceId: ID!, $name: String!, $description: String, $metadata: JSON) {
  createActivityCategory(
    serviceId: $serviceId
    name: $name
    description: $description
    metadata: $metadata
  ) {
    id
    createdAt
    updatedAt
    name
    metadata
    description
    serviceId
    slug
    deleted
  }
}
    `;
export const PostUpdateActivityCategoryDocument = gql`
    mutation postUpdateActivityCategory($id: ID!, $name: String!, $description: String, $metadata: JSON) {
  updateActivityCategory(
    id: $id
    name: $name
    description: $description
    metadata: $metadata
  ) {
    id
    name
    metadata
    description
    serviceId
    slug
    deleted
  }
}
    `;
export const PostDeleteActivityCategoryDocument = gql`
    mutation postDeleteActivityCategory($id: ID!) {
  deleteActivityCategory(id: $id) {
    serviceId
    id
  }
}
    `;
export const GetLearningOutcomesDocument = gql`
    query getLearningOutcomes($serviceId: Int!) {
  learningOutcomes(serviceId: $serviceId) {
    code
    description
    id
    system
    title
  }
}
    `;
export const GetBookedSessionsDocument = gql`
    query getBookedSessions($serviceId: ID!, $date: String!, $dateTo: String, $roomId: String) {
  bookedSessions(
    serviceId: $serviceId
    date: $date
    dateTo: $dateTo
    roomId: $roomId
  ) {
    childId
    room
    roomId
    enrolmentId
    childFirstName
    childLastName
    noObservation
  }
}
    `;
export const GetSessionsDocument = gql`
    query getSessions($serviceId: ID!, $date: String!, $dateTo: String, $roomId: String) {
  sessions(serviceId: $serviceId, date: $date, dateTo: $dateTo, roomId: $roomId) {
    childId
    room
    roomId
    enrolmentId
    childFirstName
    childLastName
    noObservation
  }
}
    `;
export const GetServiceInfoDocument = gql`
    query getServiceInfo($id: Int!) {
  service(id: $id) {
    id
    name
    opDays
    opstatus
    organisationId
  }
}
    `;
export const GetOrganisationDocument = gql`
    query getOrganisation($id: Int!) {
  organisation(id: $id) {
    id
    logoData
    name
  }
}
    `;
export const GetRoomsDocument = gql`
    query getRooms($serviceId: ID!) {
  rooms(serviceId: $serviceId) {
    id
    inactive
    name
    roomType
    serviceId
  }
}
    `;
export const GetCurrentUserDocument = gql`
    query getCurrentUser {
  me {
    id
    email
    emailVerified
    firstName
    lastName
    mfa
    jobs {
      serviceId
      serviceName
      roles
    }
  }
}
    `;
export const PostSignInDocument = gql`
    mutation postSignIn($email: String!, $password: String!) {
  signin(email: $email, password: $password) {
    accessToken
    userId
  }
}
    `;
export const PostDeleteKindyHubObjectDocument = gql`
    mutation postDeleteKindyHubObject($objId: ID!, $objType: String!) {
  deleteKindyhubObject(objId: $objId, objType: $objType) {
    status
  }
}
    `;
export const GetChildrenListDocument = gql`
    query getChildrenList($serviceId: Int!, $startDate: String, $endDate: String) {
  enrolments(serviceId: $serviceId, startDate: $startDate, endDate: $endDate) {
    id
    childId
    status
    startDate
    endDate
    noObservation
    child {
      id
      firstName
      gender
      dateOfBirth
      lastName
      profilePicture {
        id
        url
      }
      instructions {
        category
        description
      }
    }
  }
}
    `;
export const GetChildrenParentListDocument = gql`
    query getChildrenParentList($serviceId: Int!, $searchText: String) {
  enrolments(serviceId: $serviceId, searchText: $searchText) {
    id
    status
    parentId
    childId
    noObservation
    child {
      id
      firstName
      lastName
    }
    parent1 {
      id
      firstName
      lastName
      email
    }
    parent2 {
      id
      firstName
      lastName
      phone
      email
    }
  }
}
    `;
export const GetChildDetailDocument = gql`
    query getChildDetail($enrolmentId: Int!) {
  formConfig
  enrolment(id: $enrolmentId) {
    id
    childId
    status
    startDate
    endDate
    parentId
    noObservation
    child {
      id
      firstName
      dateOfBirth
      lastName
      everything
      profilePicture {
        id
        url
      }
      contacts {
        firstName
        lastName
        email
        mobile
        phone
        role
      }
    }
    parent1 {
      id
      firstName
      lastName
      phone
      email
    }
  }
}
    `;
export const PostUpdateChildProfilePictureDocument = gql`
    mutation postUpdateChildProfilePicture($childId: ID!, $captureId: ID!) {
  updateChildProfilePhoto(childId: $childId, captureId: $captureId) {
    errors
    success
  }
}
    `;
export const GetCustomTagsDocument = gql`
    query getCustomTags($serviceId: ID!) {
  customTags(serviceId: $serviceId) {
    name
    id
    description
    colour
  }
}
    `;
export const PostCreateCustomTagDocument = gql`
    mutation postCreateCustomTag($serviceId: ID!, $name: String!, $description: String, $colour: String) {
  createCustomTag(
    serviceId: $serviceId
    name: $name
    description: $description
    colour: $colour
  ) {
    id
    name
    description
    colour
  }
}
    `;
export const PostEditCustomTagDocument = gql`
    mutation postEditCustomTag($id: ID!, $name: String!, $description: String, $colour: String) {
  updateCustomTag(
    id: $id
    name: $name
    description: $description
    colour: $colour
  ) {
    id
    name
    description
    colour
  }
}
    `;
export const PostDeleteCustomTagDocument = gql`
    mutation postDeleteCustomTag($id: ID!) {
  removeCustomTag(id: $id) {
    id
    name
    description
    colour
  }
}
    `;
export const PostSaveTagsDocument = gql`
    mutation postSaveTags($objectType: String!, $objectId: ID!, $rooms: [ID!], $children: [ID!], $learningOutcomes: [ID!], $customTags: [ID!], $nextActivities: [ID!], $nextReports: [ID!], $newCustomTags: [ID!], $users: [ID!]) {
  saveTags(
    objectType: $objectType
    objectId: $objectId
    rooms: $rooms
    children: $children
    learningOutcomes: $learningOutcomes
    customTags: $customTags
    newCustomTags: $newCustomTags
    nextActivities: $nextActivities
    nextReports: $nextReports
    users: $users
  )
}
    `;
export const GetUserOrganisationRolesDocument = gql`
    query getUserOrganisationRoles($serviceId: ID!) {
  userOrganisationRoles(serviceId: $serviceId) {
    id
    email
    firstName
    lastName
    userOrgDetails {
      id
      role
      roleEnd
      roleStart
    }
  }
}
    `;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string, operationType?: string) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    getActivity(variables: GetActivityQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetActivityQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetActivityQuery>(GetActivityDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getActivity', 'query');
    },
    getActivities(variables: GetActivitiesQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetActivitiesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetActivitiesQuery>(GetActivitiesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getActivities', 'query');
    },
    getActivityCategories(variables: GetActivityCategoriesQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetActivityCategoriesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetActivityCategoriesQuery>(GetActivityCategoriesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getActivityCategories', 'query');
    },
    postSaveActivity(variables: PostSaveActivityMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<PostSaveActivityMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<PostSaveActivityMutation>(PostSaveActivityDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'postSaveActivity', 'mutation');
    },
    postCreateActivityCategory(variables: PostCreateActivityCategoryMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<PostCreateActivityCategoryMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<PostCreateActivityCategoryMutation>(PostCreateActivityCategoryDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'postCreateActivityCategory', 'mutation');
    },
    postUpdateActivityCategory(variables: PostUpdateActivityCategoryMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<PostUpdateActivityCategoryMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<PostUpdateActivityCategoryMutation>(PostUpdateActivityCategoryDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'postUpdateActivityCategory', 'mutation');
    },
    postDeleteActivityCategory(variables: PostDeleteActivityCategoryMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<PostDeleteActivityCategoryMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<PostDeleteActivityCategoryMutation>(PostDeleteActivityCategoryDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'postDeleteActivityCategory', 'mutation');
    },
    getLearningOutcomes(variables: GetLearningOutcomesQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetLearningOutcomesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetLearningOutcomesQuery>(GetLearningOutcomesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getLearningOutcomes', 'query');
    },
    getBookedSessions(variables: GetBookedSessionsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetBookedSessionsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetBookedSessionsQuery>(GetBookedSessionsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getBookedSessions', 'query');
    },
    getSessions(variables: GetSessionsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetSessionsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetSessionsQuery>(GetSessionsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getSessions', 'query');
    },
    getServiceInfo(variables: GetServiceInfoQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetServiceInfoQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetServiceInfoQuery>(GetServiceInfoDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getServiceInfo', 'query');
    },
    getOrganisation(variables: GetOrganisationQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetOrganisationQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetOrganisationQuery>(GetOrganisationDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getOrganisation', 'query');
    },
    getRooms(variables: GetRoomsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetRoomsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetRoomsQuery>(GetRoomsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getRooms', 'query');
    },
    getCurrentUser(variables?: GetCurrentUserQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetCurrentUserQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetCurrentUserQuery>(GetCurrentUserDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getCurrentUser', 'query');
    },
    postSignIn(variables: PostSignInMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<PostSignInMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<PostSignInMutation>(PostSignInDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'postSignIn', 'mutation');
    },
    postDeleteKindyHubObject(variables: PostDeleteKindyHubObjectMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<PostDeleteKindyHubObjectMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<PostDeleteKindyHubObjectMutation>(PostDeleteKindyHubObjectDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'postDeleteKindyHubObject', 'mutation');
    },
    getChildrenList(variables: GetChildrenListQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetChildrenListQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetChildrenListQuery>(GetChildrenListDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getChildrenList', 'query');
    },
    getChildrenParentList(variables: GetChildrenParentListQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetChildrenParentListQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetChildrenParentListQuery>(GetChildrenParentListDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getChildrenParentList', 'query');
    },
    getChildDetail(variables: GetChildDetailQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetChildDetailQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetChildDetailQuery>(GetChildDetailDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getChildDetail', 'query');
    },
    postUpdateChildProfilePicture(variables: PostUpdateChildProfilePictureMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<PostUpdateChildProfilePictureMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<PostUpdateChildProfilePictureMutation>(PostUpdateChildProfilePictureDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'postUpdateChildProfilePicture', 'mutation');
    },
    getCustomTags(variables: GetCustomTagsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetCustomTagsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetCustomTagsQuery>(GetCustomTagsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getCustomTags', 'query');
    },
    postCreateCustomTag(variables: PostCreateCustomTagMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<PostCreateCustomTagMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<PostCreateCustomTagMutation>(PostCreateCustomTagDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'postCreateCustomTag', 'mutation');
    },
    postEditCustomTag(variables: PostEditCustomTagMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<PostEditCustomTagMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<PostEditCustomTagMutation>(PostEditCustomTagDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'postEditCustomTag', 'mutation');
    },
    postDeleteCustomTag(variables: PostDeleteCustomTagMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<PostDeleteCustomTagMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<PostDeleteCustomTagMutation>(PostDeleteCustomTagDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'postDeleteCustomTag', 'mutation');
    },
    postSaveTags(variables: PostSaveTagsMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<PostSaveTagsMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<PostSaveTagsMutation>(PostSaveTagsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'postSaveTags', 'mutation');
    },
    getUserOrganisationRoles(variables: GetUserOrganisationRolesQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<GetUserOrganisationRolesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GetUserOrganisationRolesQuery>(GetUserOrganisationRolesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getUserOrganisationRoles', 'query');
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;