import { format } from 'date-fns'
import { GetServiceInfoQuery } from 'generated/moments-graphql/schema'

export type TOperatingDay = {
  name: string
  id: number
}

export type TOperatingDays = {
  [key: string]: TOperatingDay
}

const OPERATING_DAYS: TOperatingDays = {
  SUN: { id: 0, name: 'Sunday' },
  MON: { id: 1, name: 'Monday' },
  TUE: { id: 2, name: 'Tuesday' },
  WED: { id: 3, name: 'Wednesday' },
  THU: { id: 4, name: 'Thursday' },
  FRI: { id: 5, name: 'Friday' },
  SAT: { id: 6, name: 'Saturday' },
} as const

export const fallbackOpDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday']

export const getOpDays = (serviceInfo?: GetServiceInfoQuery['service']) => {
  return serviceInfo?.opDays?.map((day) => OPERATING_DAYS[`${day}`].name) || fallbackOpDays
}

export const disableNotOperatingDays = (operatingDays: string[] = [], date?: Date): boolean => {
  const opDays = operatingDays.length ? operatingDays : fallbackOpDays
  const day = (date && format(date, 'EEEE')) || null
  if (day) {
    return !opDays.includes(day)
  }
  return false
}
