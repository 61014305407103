import { AbilityContext } from 'common/casl/components/Can'
import { TSCRole, buildAbilityFor } from 'common/casl/config/ability'
import { useRouter } from 'next/router'
import { ReactNode, useMemo } from 'react'
import { useServiceCurrentUserInfo } from 'services/use-service-current-user-info'

interface ICaslAbilityContextProps {
  children: ReactNode
}

export const CaslAbilityContextProvider = (props: ICaslAbilityContextProps) => {
  const { FetchUserInfo } = useServiceCurrentUserInfo()
  const { data } = FetchUserInfo()
  const jobs = data?.jobs

  const {
    query: { srvid },
  } = useRouter()

  const currentUserJob = useMemo(() => {
    return jobs?.find((job) => job?.serviceId === (srvid as string))
  }, [srvid, jobs])

  const ability = buildAbilityFor(currentUserJob?.roles?.at(0) as TSCRole)

  return <AbilityContext.Provider value={ability}>{props.children}</AbilityContext.Provider>
}
