import { useMemo } from 'react'
import { useSession } from 'next-auth/react'
import { Configuration } from 'generated/restapi'
import { GraphQLClient } from 'graphql-request'

const BASE_PATH = process.env.NEXT_PUBLIC_MOMENTS_BE_URL
const GQL_BASE_PATH = `${BASE_PATH}/graphql`

export type TUserMode = 'staff' | 'parent'
interface IUseMomentsClientProps {
  scUserMode?: TUserMode
}

export const useMomentsClient = (props?: IUseMomentsClientProps) => {
  const { data: session, status } = useSession()

  const accessToken = useMemo(() => {
    return session && status === 'authenticated' ? session.accessToken : ''
  }, [session, status])

  const restConfig = useMemo(() => new Configuration({ basePath: BASE_PATH, accessToken: accessToken }), [accessToken])

  const gqlClient = useMemo(
    () =>
      new GraphQLClient(GQL_BASE_PATH, {
        headers: {
          authorization: `Bearer ${accessToken}`,
          'SC-USER-MODE': props?.scUserMode || 'staff',
        },
      }),
    [accessToken, props?.scUserMode]
  )

  return { restConfig, gqlClient, isReady: Boolean(accessToken) }
}
