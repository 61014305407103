import { useQuery } from '@tanstack/react-query'
import { useMomentsClient } from './use-moments-client'
import { getSdk, GetServiceInfoQueryVariables } from 'generated/moments-graphql/schema'

const serviceInfoKeys = {
  'serviceInfo': (params?: GetServiceInfoQueryVariables) => ['fetch-service-info', { ...params }],
}

export const useServiceServiceInfo = () => {
  const { gqlClient, isReady } = useMomentsClient()

  const FetchServiceInfo = (params: GetServiceInfoQueryVariables) => {
    const { getServiceInfo } = getSdk(gqlClient)

    const queryKey = serviceInfoKeys.serviceInfo(params)
    const queryFn = () => getServiceInfo(params)

    return useQuery({
      enabled: isReady && !!params?.id,
      queryKey,
      queryFn,
      staleTime: 1000 * 60 * 60,
      select: (data) => data?.service,
      meta: {
        errorMessage: `Error fetching service info.`
      }
    })
  }

  return { FetchServiceInfo }
}
