import { useQuery } from '@tanstack/react-query'
import { useMomentsClient } from './use-moments-client'
import { getSdk } from 'generated/moments-graphql/schema'

const userInfoKeys = {
  'userInfo': () => ['fetch-user-info'],
}

export const useServiceCurrentUserInfo = () => {
  const { gqlClient, isReady } = useMomentsClient()

  const FetchUserInfo = () => {
    const { getCurrentUser } = getSdk(gqlClient)

    const queryKey = userInfoKeys.userInfo()
    const queryFn = () => getCurrentUser()

    return useQuery({
      enabled: isReady,
      queryKey,
      queryFn,
      staleTime: 1000 * 60 * 60,
      select: (data) => data?.me,
      meta: {
        errorMessage: `Error fetching user info.`,
      },
    })
  }

  return { FetchUserInfo }
}
