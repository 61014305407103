import { useQuery } from '@tanstack/react-query'
import { GetOrganisationQueryVariables, getSdk } from 'generated/moments-graphql/schema'
import { useMomentsClient } from './use-moments-client'

const organisationInfoKeys = {
  'organisationInfo': (params?: GetOrganisationQueryVariables) => ['fetch-organisation-info', { ...params }],
}

export const useServiceOrganisationInfo = () => {
  const { gqlClient, isReady } = useMomentsClient()

  const FetchOrgInfo = (params: GetOrganisationQueryVariables) => {
    const { getOrganisation } = getSdk(gqlClient)
  

    const queryKey = organisationInfoKeys.organisationInfo(params)
    const queryFn = () => getOrganisation(params)

    return useQuery({
      queryKey,
      queryFn,
      enabled: isReady && !!params?.id,
      staleTime: 1000 * 60 * 60,
      select: (data) => data?.organisation,
      meta: {
        errorMessage: `Error fetching organisation info.`,
      },
    })
  }

  return { FetchOrgInfo }
}
