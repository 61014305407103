import { GetRoomsQueryVariables, getSdk } from 'generated/moments-graphql/schema'
// import { useApiClient } from './use-api-client'
import { useQuery } from '@tanstack/react-query'
import { useMomentsClient } from './use-moments-client'

export const roomsKeys = {
  'rooms': (params?: GetRoomsQueryVariables) => ['fetch-rooms', { ...params }],
}

export const useServiceRooms = () => {
  const { gqlClient, isReady } = useMomentsClient()

  const FetchRooms = (params: GetRoomsQueryVariables) => {
    const { getRooms } = getSdk(gqlClient)
    const queryKey = roomsKeys.rooms(params)
    const queryFn = () => getRooms(params)
    const query = useQuery({
      enabled: isReady && !!params?.serviceId,
      queryFn,
      queryKey,
      select: (data) => data?.rooms,
      meta: {
        errorMessage: `Error fetching rooms.`,
      },
    })
    return {
      queryKey,
      ...query,
    }
  }

  return {
    FetchRooms,
  }
}
